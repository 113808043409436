<template>
    <div class="code-table-wrap" v-if="showList.length || !emptyHide">
        <TableBorder>
            <el-table
                :class="{
                    animate__animated: animate,
                    animate__fadeInRight: animate,
                }"
                :data="showList"
                border
                :columns="hsTableColumn"
                :row-class-name="rowClassName"
                v-loading="tableLoading"
            >
                <el-table-column
                    v-for="item in hsTableColumn"
                    :key="item.prop"
                    :label="item.label"
                    :prop="item.prop"
                    :width="item.width"
                    :min-width="item.minWidth"
                    :align="item.align"
                    :fixed="item.fixed"
                >
                    <template slot-scope="scope">
                        <el-tooltip
                            v-if="
                                item.prop === 'element' || item.prop === 'gname'
                            "
                            :content="scope.row[item.prop]"
                            :disabled="elementTooltipDis"
                            placement="top"
                        >
                            <div
                                class="text-ellipsis-warp"
                                @mouseenter="handleEleMouseEnter"
                            >
                                {{ scope.row[item.prop] }}
                            </div>
                        </el-tooltip>
                        <div v-else-if="item.prop === 'expTaxBack'">
                            {{
                                scope.row.expTaxBack
                                    ? `${scope.row.expTaxBack}%`
                                    : ""
                            }}
                        </div>
                        <div
                            v-else-if="item.prop === 'codeTs'"
                            class="hscode-col-wrap"
                        >
                            <span>{{ scope.row[item.prop] }}</span>
                            <img
                                v-clipboard:copy="scope.row[item.prop]"
                                v-clipboard:success="copySucc"
                                src="@/assets/icon/copy.svg"
                            />
                        </div>
                        <div v-else>{{ scope.row[item.prop] }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    fixed="right"
                    width="60"
                    align="center"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            @click="handleGoDetail(scope.row)"
                        >
                            详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </TableBorder>

        <div class="footer" v-if="showPagination">
            <el-pagination
                @size-change="changePageSize"
                @current-change="changePageNo"
                :current-page.sync="pageNo"
                :page-sizes="sizeArr"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next"
                :total="total"
            ></el-pagination>
        </div>
        <code-detail ref="codeDetailRef"></code-detail>
    </div>
</template>

<script>
import { getHsCodeVague } from "@/api/analysis";
import { apiCallback } from "@/utils";
import { paginationMixin } from "@/mixins/pagination";
import { hsTableColumn } from "../constant";
import codeDetail from "./code-detail.vue";
import TableBorder from "./table-border.vue";

export default {
    name: "CodeTable",
    mixins: [paginationMixin],
    props: {
        showPagination: {
            type: Boolean, // 是否需要分页
            default: true,
        },
        list: {
            type: Array,
            deafult: [],
        },
        animate: {
            type: Boolean, // 是否需要动画
            default: true,
        },
        emptyHide: {
            type: Boolean,
            default: true, // 数据为空是否隐藏当前组件
        },
        rowClassName: {
            type: Function,
        },
    },
    components: {
        codeDetail,
        TableBorder,
    },
    computed: {
        documentsAttachedList() {
            const result = this.$store.state.txtdata.documentsAttachedList;
            return result;
        },
        showList() {
            const result = this.showPagination ? this.curPageList : this.list;
            console.log();
            return result;
        },
    },
    data() {
        return {
            hsTableColumn,
            elementTooltipDis: false,
            tableLoading: false,
        };
    },
    methods: {
        copySucc() {
            this.$message.success("复制成功！");
        },
        handleEleMouseEnter(e) {
            if (e.target.offsetWidth >= e.target.scrollWidth) {
                this.elementTooltipDis = true;
            } else {
                this.elementTooltipDis = false;
            }
        },
        handleGoDetail({ codeTs }) {
            this.$refs.codeDetailRef.open(codeTs);
        },
        loadList(param = {}) {
            const that = this;
            this.tableLoading = true;
            return new Promise((resolve, reject) => {
                const hasParam = Object.keys(param).length;
                if (hasParam) {
                    this.updatePaginationParam(param);
                }
                const curReqParam = hasParam ? param : that.paginationParam;
                getHsCodeVague({
                    current: this.pageNo,
                    size: this.pageSize,
                    ...curReqParam,
                })
                    .then((res) => {
                        const data = apiCallback(res);
                        if (!data.records.length) {
                            this.$AlertTipsDialog({
                                msg: "当前HsCode暂无匹配数据！",
                            });
                        } else {
                            this.updatePageList(data.records);
                            this.updateTotal(data.total);
                            this.listData = data.records;
                        }
                        resolve(data.records);
                    })
                    .finally(() => {
                        that.tableLoading = false;
                        resolve([]);
                    });
            });
        },
        reset() {
            this.resetPagination();
        },
    },
    mounted() {
        if (!this.documentsAttachedList.length) {
            this.$store.dispatch("txtdata/updateDocumentsAttachedList");
        }
    },
};
</script>

<style lang="stylus">
.code-table-wrap{
    .succ-bg{
        background-color: #addb96 !important;
        font-weight: bold;
    }
}
</style>

<style lang="stylus" scoped>
.code-table-wrap{
    margin: 0 auto;
    .hscode-col-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            margin-left: 4px;
            cursor: pointer;
        }
    }
    .text-ellipsis-warp{
        white-space:nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .footer{
        display: flex;
        justify-content: center;
        padding-top: 12px;
    }
}
</style>
