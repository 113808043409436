<template>
    <div class="class-analysis-wrap">
        <div class="analysis-header">
            <div
                class="header-title animate__animated animate__fadeInRight"
            ></div>
            <div class="header-desc animate__animated animate__fadeInRight">
                HS编码智能查询，7000万+条历史大数据助力推荐，归类不求人
            </div>
            <div class="header-tooltip">
                <i class="el-icon-warning"></i>
                <div style="margin-left: 4px">
                    温馨提示：以下归类仅供参考，我司不负法律责任。最终归类请以海关定义为准。若您查询即表示认同此提示。
                </div>
            </div>
            <div class="header-search">
                <div class="search-left">
                    <el-row justify="center" style="flex-grow: 1">
                        <el-input
                            v-model.trim="formData.gName"
                            placeholder="请输入中文品名或HS CODE(必填)"
                            @keyup.enter.native="handleSearch"
                            clearable
                        >
                            <span slot="prefix" class="input-required">*</span>
                        </el-input>
                    </el-row>
                    <el-row :gutter="8" style="margin-top: 8px">
                        <el-col :span="8">
                            <el-input
                                v-model.trim="formData.useTo"
                                placeholder="用途（厨房用、工业用、摩托车用等）"
                                @keyup.enter.native="handleSearch"
                                clearable
                            ></el-input>
                        </el-col>
                        <el-col :span="8">
                            <el-input
                                v-model.trim="formData.material"
                                placeholder="材质 （塑料、不锈钢、硫化橡胶等）"
                                @keyup.enter.native="handleSearch"
                                clearable
                            ></el-input>
                        </el-col>
                        <el-col :span="8">
                            <el-input
                                v-model.trim="formData.other"
                                placeholder="其他要素信息，多个用&分隔（信息越全，查询越准）"
                                @keyup.enter.native="handleSearch"
                                clearable
                            ></el-input>
                        </el-col>
                    </el-row>
                </div>
                <div class="search-right">
                    <el-button
                        type="primary"
                        style="margin-left: 8px"
                        @click="handleSearch"
                        :loading="btnLoading"
                    >
                        归大师
                    </el-button>
                    <el-button style="margin-left: 8px" @click="handleClear">
                        重置
                    </el-button>
                </div>
            </div>
        </div>
        <g-name-result v-show="searchType === 'gName' && !showCategoryTree" ref="gNameRef" @loading-change="handleLoadingChange"></g-name-result>
        <code-result
        v-show="searchType === 'code' && !showCategoryTree"
        ref="codeRef" @loading-change="handleLoadingChange"></code-result>
        <category-tree v-show="showCategoryTree"></category-tree>
    </div>
</template>

<script>
import gNameResult from './component/gName-result.vue';
import codeResult from './component/code-result.vue';
import categoryTree from './component/category-tree.vue';
export default {
    name: "ClassAnalysis",
    components: {
        gNameResult,
        codeResult,
        categoryTree
    },
    data: () => {
        return {
            formData: {
                gName: "",
                material: "",
                useTo: "",
                other: "",
            },
            btnLoading: false,
            searchType: 'gName' // gName(中文品名)、code(hs编码)
        };
    },
    computed: {
        showCategoryTree() { 
            const result = Object.keys(this.formData).some(key => this.formData[key])
            return !result;
            // return false
        },
        curSearchVal() { 
            return this.formData.gName
        }
    },
    watch: {
        curSearchVal(val) {
            if (!val) {
                this.handleClear()
            }
        }
    },
    methods: {
        handleLoadingChange(value){ 
            this.btnLoading = value
        },
        handleSearch() { 
            if (!this.formData.gName) {
                this.$message({
                    message: "请输入中文品名或HS CODE",
                    type: "warning",
                });
                return;
            }
            if (isNaN(Number(this.formData.gName))) {
                this.searchType = 'gName'
                this.$refs.gNameRef.search(this.formData)
            } else {
                this.searchType = 'code'
                this.$refs.codeRef.search(this.formData)
            }
        },
        handleClear() { 
            this.$refs.gNameRef.clear()
            this.$refs.codeRef.clear()
        },
    },
    mounted() { 
    },
};
</script>

<style lang="stylus">
.class-analysis-wrap{
    .el-input__inner{
        background-color: #EEF3FD;
        border:none;
        color: #333333;
    }
    .el-button--primary{
        font-weight: bold;
        background: linear-gradient(180deg, #588DF5 0%, #5060EE 100%);
        border:0;
        &:hover{
            background: linear-gradient(180deg, rgba(88, 141, 245, 0.95) 0%, rgba(80, 96, 238, 0.95) 100%);
        }
    }
    .el-button{
        font-weight: bold;
        border:none;
    }
}
</style>

<style lang="stylus" scoped>
.class-analysis-wrap{
    .analysis-header{
        height:15vw;
        min-height:240px;
        background-color: #f5f7fa;
        padding: 32px;
        background-image: url('../../assets/img/analysis/analysis-head-bg.jpeg');
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        .header-title{
            width:25vw;
            height: 5vw;
            min-width:380px;
            min-height: 45px;
            background-image: url('../../assets/img/analysis/header-title.png');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        .header-desc{
            font-size: 1.4rem;
            text-align: center;
            margin-bottom: 8px;
            font-weight: bold;
            color:#3D66A4;
        }
        .header-tooltip{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 12px;
            color:rgba(237, 245, 255, 1);
            background:rgba(139, 167, 238, 1);
            border-radius: 4px;
            margin-bottom: 8px;
        }
        .header-search{
            width: 70%;
            min-width:400px;
            margin: 0 auto;
            display: flex;
            .search-left{
                flex-grow: 1;
            }
            .search-right{
                flex-shrink: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
        .input-required{
            font-size: 2rem;
            line-height: 1.5;
            color: #FF4D4F;
        }
    }
}
</style>
