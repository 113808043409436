<template>
    <div :class="{'code-analysis-body':true,'single-result':singleResult, 'list-result':!singleResult && searchResult.length}">
        <div
            v-if="!searchData.code || loading"
            class="animate__animated animate__fadeIn"
        >
            <el-empty
                :image="require('../../../assets/img/empty.png')"
                :image-size="300"
                :description="emptyDesc"
            >
                <template #description v-if="loading">
                    <vue-typer
                    erase-style="clear"
                    :text="emptyDesc"
                    :type-delay="70"
                    ></vue-typer>
                </template>
            </el-empty>
        </div>
        <code-table
            v-show="!singleResult"
            ref="codeTableRef"
        ></code-table>
        <code-desc
            v-show="singleResult"
            ref="codeDescRef"
        ></code-desc>
    </div>
</template>

<script>
import { VueTyper } from "vue-typer";
import CodeTable from './code-table.vue';
import CodeDesc from './code-desc.vue';

export default {
    name: 'AnalysisCodeResult',
    components: {
        VueTyper,
        CodeTable,
        CodeDesc
    },
    data() { 
        return {
            loading:false,
            emptyDesc: "暂无数据...",
            searchData: {
                code:''
            },
            searchResult: []
        }
    },
    computed: {
        singleResult() { 
            return this.searchResult.length === 1
        }
    },
    methods: {
        search(data) { 
            this.clear();
            this.searchData.code = data.gName
            this.$emit('loading-change', true)
            this.emptyDesc = '正在处理中，请稍候...'
            this.loading = true
            this.$refs.codeTableRef.loadList({ code: data.gName }).then((list) => {
                this.searchResult = list;
                if (list.length === 1) { 
                    const curCode = list[0].codeTs;
                    this.$refs.codeDescRef.init(curCode)
                }
                this.loading = false; 
                this.emptyDesc = '暂无数据...' 
                this.$emit('loading-change',false)
            })
        },
        clear() {
            this.emptyDesc = '暂无数据...' 
            this.searchData.code = '';
            this.listData = [];
            this.searchResult = [];
            this.$refs.codeTableRef.reset();
            this.$refs.codeDescRef.reset();
        },
    },
}
</script>

<style lang="stylus">
.code-analysis-body{
    width:70%;
    min-height: 50vh;
    margin: 0 auto;
    background-color: #fff;;
    overflow: auto;
    border-radius: 0 0 4px 4px;
    padding: 24px;
    &.list-result{
        padding: 70px 20px 12px 70px;
    }
    .el-empty__description .vue-typer .custom {
        font-size: 14px !important;
        color: #909399;
    }
}
</style>